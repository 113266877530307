function boom() {
  const popups = document.querySelectorAll(".bowe-popup-component");

  if (!popups.length) return null;

  popups.forEach((popup) => {
    const toggle = popup.querySelector(".popup-trigger");
    const popupWrapper = popup.querySelector(".popup");

    const bgColor = popup.querySelector(".popup-bg-color");
    const close = popup.querySelector(".popup-inner-close");

    [toggle, bgColor, close].forEach((item) => {
      item.addEventListener("click", () => {
        //bgColor.classList.toggle("active");
        //close.classList.toggle("active");
        popupWrapper.classList.toggle("active");
      });
    });
  });
}

document.addEventListener("DOMContentLoaded", boom);
